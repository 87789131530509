<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px; height: 100%;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 0;") CÓMO UTILIZARLO
          div.d-flex.flex-column.justify-center.fill-height.pl-10
            ul
              li Si tenemos el Price Entry más bajo, quizás podemos subirlo o mantenerlo como táctica de percepción de precios bajos.
              li Si tenemos mucha dispersión de precios  deberíamos  intentar que esta sea por causa de que los Price Premium son mas altos y no por que nuestros  Price Entry son demasiado bajos.
              li En el caso que sea una familia con gran demanda o peso en el mix de venta nos interesa que el numero de Ítems ofertados no este muy por debajo  de la competencia, pues la  poca variedad, aunque facilite las operaciones, afecta a la percepción de calidad de los clientes y a la frecuencia de compra.

  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px; height: 100%;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 0;") HOW TO USE IT
          div.d-flex.flex-column.justify-center.fill-height.pl-10
            ul
              li If we have the lowest Price Entry, perhaps we can raise or maintain it as a low-cost perception tactic.
              li If we have a lot of price dispersion, we should try to ensure that the Premium Price is higher and not because our Price Entry is too low.
              li If it is a family with high demand or weight in the sales mix, we are interested that the number of Items offered is not far below the competition because of the little variety. However, it facilitates operations, affects the perception of quality of customers and the frequency of purchase.
</template>

<script>
export default {
  name: "Card_3",
  components: {}
}
</script>

<style scoped>
ul {
  list-style: none;
}

ul li {
  position: relative;
  margin-bottom: 2em;
}

ul li::before {
  content: "\2022";
  color: #4A90E2;
  font-weight: bold;
  display: inline-block;
  width: 10px;
  height: 10px;
  font-size: 33px;
  position: absolute;
  top: -14px;
  left: -24px;
}
</style>
